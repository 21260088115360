

const ENV = process.env.NODE_ENV; // 'production' or 'development'

export const config = {
  backendUrl:
    ENV === 'production'
      ? process.env.REACT_APP_BACKEND_URL_PROD || 'http://production-backend-url.com'
      : process.env.REACT_APP_BACKEND_URL_DEV || 'http://localhost:3005',

  db: {
    user: process.env.DB_USER || 'defaultUser',
    password: process.env.DB_PASSWORD || 'defaultPassword',
    database: process.env.DB_DATABASE || 'defaultDatabase',
    port: parseInt(process.env.DB_PORT, 10) || 5432,
    host: process.env.DB_HOST || 'localhost',
  },

  jwtSecret: process.env.JWT_SECRET || 'defaultJwtSecret',
  sessionSecret: process.env.SESSION_SECRET || 'defaultSessionSecret',

  paths: {
    images:
      ENV === 'production'
        ? '/www/html/hotponies/public/images'
        : 'b:/public/images',
    pics:
      ENV === 'production'
        ? '/www/html/hotponies/public/pics'
        : 'b:/public/pics',
    documents:
      ENV === 'production'
        ? '/www/html/hotponies/public/documents'
        : 'b:/public/documents',
  },
};






// // // src/config.js
// // export const config = {
// //     backendUrl: process.env.REACT_APP_BACKEND_URL,
// //   };
  
 
// // src/config.js
// export const config = {
//     backendUrl: process.env.REACT_APP_BACKEND_URL || 'http://localhost:3005',
//     db: {
//         user: process.env.DB_USER || 'defaultUser',
//         password: process.env.DB_PASSWORD || 'defaultPassword',
//         database: process.env.DB_DATABASE || 'defaultDatabase',
//         port: parseInt(process.env.DB_PORT, 10) || 5432,
//         host: process.env.DB_HOST || 'localhost',
//     },
//     jwtSecret: process.env.JWT_SECRET || 'defaultJwtSecret',
//     sessionSecret: process.env.SESSION_SECRET || 'defaultSessionSecret',
//     paths: {
//         // images: '/ramdrive/public/images',
//         // pics: '/ramdrive/public/pics',
//         // documents: '/ramdrive/public/documents'
//         images: 'b:/public/images',
//         pics: 'b:/public/pics',
//         documents: 'b:/public/documents'
//     }
// };



// // // Usage example in a Node.js file or react component
// // import { config } from './config';
// // console.log(config.db.user);  // Output: 'postgres'
