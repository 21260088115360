


import React, { useEffect, useState } from "react";

const basePath = process.env.REACT_APP_API_BASE_URL; // Backend URL

const PreLoadedImages = ({ images = [], onProgress = null, retryAttempts = 3, timeoutMs = 5000, children }) => {
  const [loadedImages, setLoadedImages] = useState([]);
  const [loadingErrors, setLoadingErrors] = useState([]);

  useEffect(() => {
    const preloadImages = async () => {
      const totalImages = images.length;
      if (totalImages === 0) {
        return;
      }

      const loadImageWithRetry = (src, retries) =>
        new Promise((resolve, reject) => {
          const attemptLoad = (attempt) => {
            const img = new Image();
            const timer = setTimeout(() => {
              if (attempt < retries) {
                attemptLoad(attempt + 1);
              } else {
                reject(new Error(`Failed to load image: ${src}`));
              }
            }, timeoutMs);

            img.onload = () => {
              clearTimeout(timer);
              resolve(img);
            };

            img.onerror = () => {
              clearTimeout(timer);
              if (attempt < retries) {
                attemptLoad(attempt + 1);
              } else {
                reject(new Error(`Failed to load image: ${src}`));
              }
            };

            img.src = `${basePath}/images/${src}`;
          };

          attemptLoad(0);
        });

      const results = await Promise.allSettled(
        images.map((src) => loadImageWithRetry(src, retryAttempts))
      );

      const successful = results.filter((result) => result.status === "fulfilled").map((result) => result.value);
      const failed = results.filter((result) => result.status === "rejected").map((result) => result.reason);

      setLoadedImages(successful);
      setLoadingErrors(failed);

      if (onProgress) {
        onProgress({ loaded: successful.length, total: totalImages });
      }
    };

    preloadImages();
  }, [images, onProgress, retryAttempts, timeoutMs]);

  return (
    <>
      {/* <ul>
        {loadedImages.map((img, index) => (
          <li key={img.src || index}>{img.src}</li> // Add `key` prop to each <li>
        ))}
      </ul> */}
      {children && children(loadedImages, loadingErrors)}
    </>
  );
};

export default PreLoadedImages;




// import React, { useEffect, useState } from "react";

// const basePath = process.env.REACT_APP_API_BASE_URL; // Backend URL

// const PreLoadedImages = ({ images = [], onProgress = null, retryAttempts = 3, timeoutMs = 5000, children }) => {
//   const [loadedImages, setLoadedImages] = useState([]);
//   const [loadingErrors, setLoadingErrors] = useState([]);

//   useEffect(() => {
//     const preloadImages = async () => {
//       const totalImages = images.length;
//       if (totalImages === 0) {
//         // No images to load, call children immediately
//         return;
//       }

//       const loadImageWithRetry = (src, retries) =>
//         new Promise((resolve, reject) => {
//           const attemptLoad = (attempt) => {
//             console.log(`Attempting to load image: ${src}, Attempt: ${attempt + 1}`);
//             const img = new Image();
//             const timer = setTimeout(() => {
//               console.warn(`Timeout loading image: ${src}`);
//               if (attempt < retries) {
//                 console.warn(`Retrying image: ${src}, Attempt: ${attempt + 1}`);
//                 attemptLoad(attempt + 1);
//               } else {
//                 reject(new Error(`Image failed after ${retries} attempts: ${src}`));
//               }
//             }, timeoutMs);

//             img.onload = () => {
//               clearTimeout(timer);
//               console.log(`Image loaded successfully: ${src}`);
//               resolve(img);
//             };

//             img.onerror = (error) => {
//               clearTimeout(timer);
//               console.error(`Error loading image: ${src}, Attempt: ${attempt + 1}`, error);
//               if (attempt < retries) {
//                 attemptLoad(attempt + 1);
//               } else {
//                 reject(error);
//               }
//             };

//             img.src = `${basePath}/images/${src}`;
//           };

//           attemptLoad(0);
//         });

//       const results = await Promise.allSettled(
//         images.map((src) => loadImageWithRetry(src, retryAttempts))
//       );

//       const successful = results.filter((result) => result.status === "fulfilled").map((result) => result.value);
//       const failed = results.filter((result) => result.status === "rejected").map((result) => result.reason);

//       // Check if images are in the browser cache
//       const cachedImages = successful.filter((img) => img.complete);
//       if (cachedImages.length > 0) {
//         alert(`Track Images Loaded: ${cachedImages.length} of ${totalImages} images are in cache.`);
//       } else {
//         alert("No images found in the browser cache.");
//       }

//       setLoadedImages(successful);
//       setLoadingErrors(failed);

//       // Progress tracking
//       if (onProgress) {
//         onProgress({ loaded: successful.length, total: totalImages });
//       }

//       console.log(`Preloaded ${successful.length} images successfully.`);
//       if (failed.length > 0) {
//         console.error(`${failed.length} images failed to load:`, failed);
//       }
//     };

//     preloadImages();
//   }, [images, onProgress, retryAttempts, timeoutMs]);

//   // Wait until all images are attempted to load
//   if (loadedImages.length < images.length) {
//     return null;
//   }

//   // Identify special images
//   const startImage = loadedImages.find((img) => img.src.includes("start.gif"));
//   const gateImage = loadedImages.find((img) => img.src.includes("startGate.gif"));
//   const trackImage = loadedImages.find((img) => img.src.includes("track.gif"));

//   // Call children with the three images
//   return <>{children(startImage, gateImage, trackImage)}</>;
// };

// export default PreLoadedImages;
