






import React, { useEffect, useContext } from 'react';
import axios from 'axios';
import { FreeContext } from "../Pages/FreeContext";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const FreeRandomData = ({ count }) => {
  const { setRandomData } = useContext(FreeContext);

  useEffect(() => {
    // Function to fetch random records from the API
    const fetchRandomRecords = async () => {
      const randomData = [];
      
      // Loop through the count and fetch data with delay
      for (let i = 0; i < count; i++) {
        try {
          const response = await axios.get(`${apiUrl}/api/random-records/jockeys/${i + 1}`);
          randomData.push(response.data);
          
          // Add a delay of 500ms between requests
          if (i < count - 1) {
            await new Promise((resolve) => setTimeout(resolve, 500));
          }
        } catch (error) {
          console.error("Error fetching random record: ", error);
        }
      }

      // Once all data is fetched, set it to the context
      setRandomData(randomData);
    };

    fetchRandomRecords();
  }, [count, setRandomData]);

  return null;
};

export default FreeRandomData;





// import React, { useEffect, useContext } from 'react';
// import axios from 'axios';
// import { FreeContext } from "../Pages/FreeContext";
// const apiUrl = process.env.REACT_APP_API_BASE_URL;

// const FreeRandomData = ({ count }) => { // Accept 'count' as a prop from the calling component
//   const { setRandomData } = useContext(FreeContext);

//   useEffect(() => {
//     // Function to fetch random records from the API
//     const fetchRandomRecords = async (endpoint) => {
//       try {
//         const response = await axios.get(`${apiUrl}/random-records/${endpoint}/${count}`);
//         const randomRecords = response.data;
//         return randomRecords;
//       } catch (error) {
//         console.error(`Error fetching ${endpoint} data:`, error);
//         return [];
//       }
//     };

//     // Define the endpoints to fetch random records
//     const endpoints = ['horses', 'jockeys', 'trainers', 'owners', 'tracks'];

//     // Fetch random records for each endpoint and update the context state
//     const fetchData = async () => {
//       const randomData = {};
//       for (const endpoint of endpoints) {
//         // console.log(`Fetching ${count} random records for: ${endpoint}`);
//         const records = await fetchRandomRecords(endpoint);
//         // console.log(`Fetched ${count} random records for: ${endpoint}`, records);
//         randomData[endpoint] = records;
//       }
//       // console.log('Fetched data:', randomData);

//       setRandomData(randomData);
//     };

//     fetchData();
//   }, [setRandomData, count]); 

//   return null;
// };

// export default FreeRandomData;
