// import React from "react";
// import ReactDOM from "react-dom";
// import { QueryClient, QueryClientProvider } from "react-query";
// import App from "./App";
// import "./index.css";
// import { RaceProvider } from "./Components/Pages/RaceContext";
// import AuthProvider from "./Components/Modules/Auth/AuthContext";
// import PreLoadedImages from "./Components/Modules/PreLoadedImages";

// const apiUrl = process.env.REACT_APP_API_BASE_URL;

// if (!apiUrl) {
//   throw new Error("API URL is not defined. Please set REACT_APP_API_BASE_URL in the .env file.");
// }

// const queryClient = new QueryClient();
// const imagesToPreload = ["start.gif", "startGate.gif", "track.gif"];

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <QueryClientProvider client={queryClient}>
//     <AuthProvider>
//       <RaceProvider>
//         <PreLoadedImages
//           images={imagesToPreload}
//           onProgress={({ loaded, total }) => console.log(`Loaded ${loaded} of ${total} images`)}
//           retryAttempts={3}
//           timeoutMs={5000}
//         >
//           <App />
//         </PreLoadedImages>
//       </RaceProvider>
//     </AuthProvider>
//   </QueryClientProvider>
// );



import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import App from "./App";
import "./index.css";
import { RaceProvider } from "./Components/Pages/RaceContext";
import AuthProvider from "./Components/Modules/Auth/AuthContext";
import PreLoadedImages from "./Components/Modules/PreLoadedImages";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

if (!apiUrl) {
  throw new Error("API URL is not defined. Please set REACT_APP_API_BASE_URL in the .env file.");
}

const queryClient = new QueryClient();
const imagesToPreload = ["start.gif", "startGate.gif", "track.gif"];

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <RaceProvider>
        <PreLoadedImages
          images={imagesToPreload}
          onProgress={({ loaded, total }) => console.log(`Loaded ${loaded} of ${total} images`)}
          retryAttempts={3}
          timeoutMs={5000}
        >
          {({ loadedImages, loadingErrors }) => (
            <App loadedImages={loadedImages} loadingErrors={loadingErrors} />
          )}
        </PreLoadedImages>
      </RaceProvider>
    </AuthProvider>
  </QueryClientProvider>
);
